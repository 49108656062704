.energy-hero {
    background: url(../../assets/images/bg-default.svg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 80vh;
}

.energy,
.every {
    /* create a linear gradient with the desired colors */
        background: linear-gradient(266.7deg, #F7941D 0%, #EF4136 100%), #EF4634;
        /* add some padding to the text to make it more readable */
        padding: 5px;
        /* use the background clip property to clip the gradient to the text */
        -webkit-background-clip: text;
        background-clip: text;
        /* use the text fill color property to make the text transparent */
        -webkit-text-fill-color: transparent;
        /* text-fill-color: transparent; */
}

@media (max-width: 766px){
    .energy,
    .every {
       font-size: 4rem;
    }
}

@media (max-width: 500px){
    .energy,
    .every {
       font-size: 2.5rem;
    }
}