select {
    /* appearance: none; */
    border: 1px solid #ccc;
    background-color: #fff;
    max-width: 981px;
    font-size: 16px;
    padding: 5px;
    border-top: none;
    border-left:none ;
    border-right:none ;
}