.sectorDropdown {
  width: 213px;
  background: #fef3e1;
  box-shadow: 0px 4px 28px 5px rgba(142, 78, 0, 0.15);
  border-radius: 6px;
  height: 175px;
}

.sectorDropdown p {
  cursor: pointer;
  padding: 0.5rem;
  line-height: 165.75%;
  padding-top: 20px;
}

.sectorDropdown img {
    padding-right: 10px;
}

.mobileDropdownWrapper {
  width: 223px;
  background: #fef3e1;
  box-shadow: 0px 4px 28px 5px rgba(142, 78, 0, 0.15);
  border-radius: 6px;
  min-height: 175px;
}

.mobileDropdown {
  width: 213px;
}

.mobileDropdown p {
  cursor: pointer;
  line-height: 165.75%;
  padding-bottom: 10px;
  font-weight: 300;
}

.mobileDropdown img {
    padding-right: 10px;
}