input {
    background: none !important;
}
input[type='radio']:after {
    width: 20px;
    height: 20px;
    border-radius: 15px;
    top: -3px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 20px;
    height: 20px;
    border-radius: 15px;
    top: -3px;
    left: -1px;
    position: relative;
    background-color: #F7941D;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}